/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

interface PriceType {
  excess: number;
  price: string;
  // eslint-disable-next-line camelcase
  price_after_discount: number | null;
  price_with_breakdown?: number;
  price_with_breakdown_after_discount?: number;
}

export interface UtilDataObjectProps {
  useExcessPrice: boolean;
  selectedExcess: number;
  finalPricesByExcess: PriceType[];
  isBreakdownCoverSelected: boolean;
}

export interface PriceDataObjectProps extends UtilDataObjectProps {
  savedPrice: string;
  indicativePrice: string;
}

export interface InitialPriceDataObjectProps extends UtilDataObjectProps {
  savedInitialPrice: string;
  initialPriceWithBreakdownCover: string;
}

export const getCurrentPrice = (
  excessSelected: number,
  finalPricesByExcess: PriceType[],
): number | null => {
  const priceObj = finalPricesByExcess.find((element) => element.excess === excessSelected);
  if (priceObj) return Number(priceObj.price);
  return 0;
};

export const getPricesByExcess = (
  excessSelected: number,
  finalPricesByExcess: PriceType[],
): PriceType =>
  finalPricesByExcess.filter((priceByExcess) => priceByExcess.excess === excessSelected)[0];

export const getPriceDifference = (
  price: number,
  currentPrice: number,
  selected: boolean,
): string => {
  if (!price) return '';
  if (selected) return 'Selected';

  const absPrice = Math.abs(price);
  const absCurrentPrice = Math.abs(currentPrice);

  if (absPrice >= absCurrentPrice) {
    return `Add £${(absPrice - absCurrentPrice).toFixed(2)}`;
  }
  return `Save £${(absCurrentPrice - absPrice).toFixed(2)}`;
};

// adds a dummy 250 excess that will be greyed out if showUnavailableExcess is true
export const appendDummyExcessIfRequired = (
  finalPricesByExcess: Array<PriceType>,
  show: boolean,
): Array<PriceType> => {
  const excesses = finalPricesByExcess;
  if (show && excesses.length === 1) {
    excesses.push({
      excess: 250,
      price: null,
      price_after_discount: null,
    });
  }
  return excesses;
};

export const getInitialPrice = (initialPriceDataObject: InitialPriceDataObjectProps): string => {
  if (initialPriceDataObject.useExcessPrice) {
    const pricesByExcess = getPricesByExcess(
      initialPriceDataObject.selectedExcess,
      initialPriceDataObject.finalPricesByExcess,
    );

    if (initialPriceDataObject.isBreakdownCoverSelected) {
      return pricesByExcess?.price_with_breakdown?.toString() || '';
    }

    return pricesByExcess?.price;
  }

  if (initialPriceDataObject.isBreakdownCoverSelected) {
    return initialPriceDataObject.initialPriceWithBreakdownCover;
  }

  return initialPriceDataObject.savedInitialPrice;
};

export const getDiscountedPrice = (
  pricesByExcess: PriceType,
  isBreakdownCoverSelected: boolean,
): number | null => {
  const {
    price_after_discount: priceByExcessDiscounted,
    price_with_breakdown_after_discount: priceWithBreakdownDiscounted,
  } = pricesByExcess;

  if (isBreakdownCoverSelected) {
    return priceWithBreakdownDiscounted || priceByExcessDiscounted;
  }
  return priceByExcessDiscounted;
};

export const getPrice = (priceDataObject: PriceDataObjectProps): string => {
  if (priceDataObject.useExcessPrice) {
    if (!priceDataObject.finalPricesByExcess.length) return priceDataObject.savedPrice;
    const pricesByExcess =
      getPricesByExcess(priceDataObject.selectedExcess, priceDataObject.finalPricesByExcess) || {};
    return (
      getDiscountedPrice(pricesByExcess, priceDataObject.isBreakdownCoverSelected)?.toString() || ''
    );
  }

  return priceDataObject.savedPrice || priceDataObject.indicativePrice;
};
