import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';

import referralListenerMiddleware from '@redux/features/referral/referralListeners';
import { quoteApi } from '@services/api/quoteRtk';
import persistedReducer, { sessionStorageEnhancer } from './enhancer/sessionStorage';
import {
  sentry,
  prefillData,
  formInitMiddleware,
  sagaMiddleware,
  gaTrackingMiddleware,
  buildQueryParamToCookieMiddleware,
} from './middlewares';

import { history } from '../router';
import type { RootState } from './reducer';
import rootReducer from './reducer';

export const setupStore = (
  preloadedState?: Partial<RootState>,
  persistState = true,
): ReturnType<typeof configureStore> => {
  const initialState = window.REDUX_INITIAL_DATA ? window.REDUX_INITIAL_DATA : preloadedState;
  const reducer = persistState ? persistedReducer(rootReducer) : rootReducer;
  const routerMiddlewareWithHistory = routerMiddleware(history);

  const store = configureStore<RootState>({
    reducer,
    preloadedState: initialState,
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sessionStorageEnhancer),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // We're disabling these to prevent the storm of errors that are raised
        // until we can correctly remove moment from all state, and stop giving action creators
        // entire objects to pass around, e.g. fetchMeFailure(error) passing in the entire error instance
        actionCreatorCheck: false,
        immutableCheck: false,
        serializableCheck: false,
      }).concat(
        routerMiddlewareWithHistory,
        buildQueryParamToCookieMiddleware('bp_e'),
        sentry,
        // RTK Query middlewares
        // (for some reason, doesn't work if placed after pageOrderGuard)
        quoteApi.middleware,
        sagaMiddleware,
        formInitMiddleware,
        prefillData,
        gaTrackingMiddleware,
        // Redux Toolkit middlewares
        referralListenerMiddleware.middleware,
      ),
    devTools: process.env.NODE_ENV !== 'production',
  });

  store.runSaga = sagaMiddleware.run;

  return store;
};

const store = setupStore();
export type StoreType = typeof store;
export type AppDispatch = typeof store.dispatch;

export default store;
