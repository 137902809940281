import Cookies from 'js-cookie';
import { routerMiddleware, LOCATION_CHANGE } from 'connected-react-router';
import Debug from 'debug';
import { applyMiddleware } from 'redux';
import { initialize } from 'redux-form';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';
import { logPageView } from '@services/ecommerceTracking';
import getUtmCookieValue from '@services/getUtmCookieValue';
import { setUserField, types as userActions } from '@reducer/quote/user.actions';
import handleError from '@redux/utils/errorHandler';
import dataProvider from './enhancer/dataProvider';

import { types as saveTypes } from './reducer/quote/save.actions';

import { history } from '../router';

const debugMiddlewares = Debug('veygo:redux:middlewares');

export const sentry = (store) => (next) => (action) => {
  if (action.type === saveTypes.SAVE_QUOTE_FAILURE) {
    if (
      ![
        'CARNOTFOUND',
        'EXISTINGDETAILSLIC',
        'EXISTINGDETAILSNLIC',
        'LICENCENOTFOUND',
        'QUOTEEXPIRED',
        'QUOTEPAID',
        'QUOTEINVALID',
        'UNCONFIRMEDPROVISIONAL',
        'THIRDPARTYFAILURE',
        'EXISTINGDETAILSNLIC',
      ].includes(action.error.code)
    ) {
      // Check if we can just straight log this error to Sentry and immediately return.
      // If this gives much better error reporting, we can just delete the rest of this function.
      if (action.error instanceof Error) {
        handleError(action.error);
        return next(action);
      }

      const firstLevelCode = action.error && action.error.code;
      const secondLevelCode =
        action.error &&
        Object.keys(action.error)
          .map((key) => action.error[key].code)
          .find((code) => !!code);

      let sentryTitle = `Error in front: ${action.type}`;
      if (action.error) sentryTitle += ` | ${JSON.stringify(action.error).substring(0, 20)}`;
      if (firstLevelCode) sentryTitle += ` | ${firstLevelCode}`;
      if (secondLevelCode) sentryTitle += ` | ${secondLevelCode}`;

      Sentry.withScope((scope) => {
        scope.setExtra('id', store.getState().quote.save.uuid);
        scope.setExtra('type', action.type);
        scope.setExtra('error', action.error);
        scope.setExtra('firstLevelCode', firstLevelCode);
        scope.setExtra('secondLevelCode', secondLevelCode);
        Sentry.captureMessage(sentryTitle);
      });
    }
  }

  return next(action);
};

export const formInitMiddleware = (store) => (next) => (action) => {
  next(action);

  const debug = debugMiddlewares.extend('formInitMiddleware');

  if (action.type === LOCATION_CHANGE || action.type === userActions.PREFILL_DRIVER) {
    let destination = store.getState().router.location.pathname;

    if (action.payload?.location.pathname) {
      destination = action.payload.location.pathname;
      destination = destination.replace(/^\/+/g, ''); // Remove leading slashes
    }

    if (Object.keys(dataProvider).includes(destination)) {
      const { form, fields } = dataProvider[destination](store.getState());
      store.dispatch(initialize(form, fields));
      debug('initialised form', form, 'with fields', fields);
    }
  }
};

// Track changes GA related cookies whenever they update
// Aims to stop a race condition between the CMS and quote engine
export const gaTrackingMiddleware = (store) => (next) => (action) => {
  next(action);
  if (action.type === LOCATION_CHANGE) {
    const product = store.getState().quote.product.productType || 'csi';
    logPageView(product);
  }
  // UTM source manager
  const utmSource = getUtmCookieValue('utmSource');
  const utmMedium = getUtmCookieValue('utmMedium');
  const utmCampaign = getUtmCookieValue('utmCampaign');

  // awc cookie
  const awc = getUtmCookieValue('awc');

  const {
    utmSource: savedUtmSource,
    utmMedium: savedUtmMedium,
    utmCampaign: savedUtmCampaign,
    awc: savedAwc,
  } = store.getState().quote.user;
  // Only update the source if the cookie value differs from our saved source
  if (utmSource && utmSource !== savedUtmSource) {
    store.dispatch(setUserField('utmSource', utmSource));
  }
  if (utmMedium && utmMedium !== savedUtmMedium) {
    store.dispatch(setUserField('utmMedium', utmMedium));
  }
  if (utmCampaign && utmCampaign !== savedUtmCampaign) {
    store.dispatch(setUserField('utmCampaign', utmCampaign));
  }

  if (awc && awc !== savedAwc) {
    store.dispatch(setUserField('awc', awc));
  }
};

export const prefillData = (store) => (next) => (action) => {
  const state = store.getState();
  const actionClone = action;

  if (action.extra && state.account.login.loggedIn) {
    if (action.extra.accountData) {
      actionClone.data = state.account;
    }

    if (action.extra.preferredCar && state.quote.user.connection) {
      const preferredCar = state.account.connections.preferredCars.find(
        (p) => p.uuid === state.quote.user.connection,
      );
      actionClone.preferredCar = preferredCar;
    }
  }

  return next(actionClone);
};

/**
 * Builder that returns middleware to capture URI query parameters and store them in a cookie.
 * Intended for use on query parameters that appear in the URI when the user arrives on a site,
 * but react-router removes the query parameters when it redirects them to the first page they see.
 * @param {string[]} parameterNames - The names of the query parameters to capture.
 * @example
 * ```
 * const queryParamToCookieMiddleware = buildQueryParamToCookieMiddleware('utm_source', 'utm_medium', 'utm_campaign');
 * ```
 */
export const buildQueryParamToCookieMiddleware = (...parameterNames) => (store) => (next) => (
  action,
) => {
  const debug = debugMiddlewares.extend('buildQueryParamToCookieMiddleware');

  if (action.type === LOCATION_CHANGE) {
    const searchParams = new URLSearchParams(window.location.search);

    parameterNames.forEach((parameterName) => {
      const parameterValue = searchParams.get(parameterName);
      if (parameterValue) {
        debug(`Query parameter ${parameterName} found`);

        const hostnameParts = window.location.hostname.split('.');
        const cookieDomain = `.${hostnameParts.slice(-2).join('.')}`;

        debug(
          `Setting cookie for ${parameterName} with value ${parameterValue} on domain ${cookieDomain}`,
        );
        Cookies.set(parameterName, parameterValue, { expires: 7, domain: cookieDomain });
      }
    });
  }

  return next(action);
};

export const sagaMiddleware = createSagaMiddleware({
  onError: (error, extra) => handleError(error, { ...extra }),
});
