import moment from 'moment';

import factory from './factory';
import { validationTests } from '../formValidation';
import { cardholderTypeMap, licenceTypeMap, ccClaimsMap, statusMap } from './maps';

const buildDate = (dateObject) => {
  if (!dateObject) return null;

  return dateObject.format('YYYY-MM-DD');
};

const transformProductType = (productType) => {
  switch (productType) {
    case 'tc':
      return 'tc';
    case 'newdriver':
      return 'newdriver';
    default:
      return 'veygo';
  }
};

/*
 * DEPRECATED - Now moving to single date time field.
 * Extension still using this.
 */
export const buildDateTime = (dateObject, time) => {
  if (!dateObject) return null;

  const { hour, minute } = time;
  const clonedMoment = moment(dateObject).hour(hour).minute(minute);

  // Convert to UTC time as the back end is expecting this format. The drop down the user sees is in GMT.
  return clonedMoment.tz('UTC').format('YYYY-MM-DD HH:mm:ss');
};

const setAlternateVehicle = (hasAlternateVehicle, typeOfUsage) => {
  return hasAlternateVehicle === 'false' ? 'no' : typeOfUsage;
};

const direct = (input, output) => {
  const { isSubscription } = input.product;
  return {
    billing_address: input.payment.billingAddress.postcode ? output : null,
    car_abi_code: parseInt(output.car_abi_code),
    cardholder_type: cardholderTypeMap[input.payment.cardHolderType],
    card_last_digits: input.payment.cardNumber.slice(12),
    cc_number_of_claims: input.claims.hasClaims ? ccClaimsMap[input.claims.numberOfClaims] : 0,
    driver_date_of_birth: buildDate(input.driver.birthdate),
    owner_date_of_birth: buildDate(input.owner.birthdate),
    duration_end: input.duration.endDateTime,
    duration_start: input.duration.startDateTime,
    licence_country: input.licence.type === 'non_uk' ? input.licence.country : 'GBR',
    licence_manual_entry: true,
    licence_number: input.licence.licenceNumber,
    licence_type: licenceTypeMap[output.licence_type],
    ncb_years: input.noClaimsBonus.haveNCB ? input.noClaimsBonus.level : 0,
    user_type: statusMap[input.user.status],
    driver_phone_number: validationTests.phonenumber(input.driver.phoneNumber)
      ? input.driver.phoneNumber
      : '',
    owner_phone_number: validationTests.phonenumber(input.owner.phoneNumber)
      ? input.owner.phoneNumber
      : '',
    driver_email: validationTests.email(input.driver.email) ? input.driver.email : '',
    owner_email: validationTests.email(input.owner.email) ? input.owner.email : '',
    document_delivery_preference:
      input.payment.paperlessDelivery || input.product.productType === 'newdriver'
        ? 'digital'
        : input.payment.deliveryPreference,
    full_provisional_date_confirmed: buildDate(input.licence.fullLicenceDate),
    platform: transformProductType(input.product.productType),
    ...(input.product.productType === 'tc' && {
      additional_quote_data: {
        reason_for_cover: input.tempcover.reasonForCover,
        other_reason_for_cover: input.tempcover.otherReasonForCover,
        is_named_driver: input.tempcover.isNamedDriver,
        ...(isSubscription && {
          expected_mileage: parseInt(input.newdriver.expectedMileage),
          overnight_location: input.newdriver.overnightLocation,
          alternate_vehicle: setAlternateVehicle(
            input.newdriver.hasAlternateVehicle,
            input.newdriver.typeOfUsage,
          ),
        }),
      },
    }),
    ...(input.product.productType === 'newdriver' && {
      additional_quote_data: {
        expected_mileage: parseInt(input.newdriver.expectedMileage),
        overnight_location: input.newdriver.overnightLocation,
        alternate_vehicle: setAlternateVehicle(
          input.newdriver.hasAlternateVehicle,
          input.newdriver.typeOfUsage,
        ),
      },
    }),
    privacy_accepted: input.legal.privacyPolicyConfirmed,
    is_subscription: isSubscription,
  };
};

export default factory(
  {
    'car.abiCode': 'car_abi_code',
    'car.engineSize': 'car_engine_size',
    'car.hasInsurance': 'car_has_insurance',
    'car.mainInsurer': 'car_main_insurer',
    'car.make': 'car_make',
    'car.model': 'car_model',
    'car.registrationNumber': 'car_registration_number',
    'car.transmission': 'car_transmission',
    'car.value': 'car_value',
    'car.year': 'car_year',
    'car.termsOfCoverConfirmed': 'car_terms_of_cover_confirmed',
    'claims.hasClaims': 'cc_claims',
    'claims.numberOfClaims': 'cc_number_of_claims',
    'driver.address.county': 'driver_address_county',
    'driver.address.country': 'driver_address_country',
    'driver.address.flatNumber': 'driver_address_flat_no',
    'driver.address.houseName': 'driver_address_house_name',
    'driver.address.houseNumber': 'driver_address_house_no',
    'driver.address.isManual': 'driver_address_is_manual',
    'driver.address.road': 'driver_address_road',
    'driver.address.line1': 'driver_address_line1',
    'driver.address.line2': 'driver_address_line2',
    'driver.address.postcode': 'driver_address_postcode',
    'driver.address.town': 'driver_address_town',
    'driver.address.lookupId': 'driver_address_lookup_id',
    'driver.address.udprn': 'driver_address_udprn',
    'driver.birthdate': 'driver_date_of_birth',
    'driver.email': 'driver_email',
    'driver.firstName': 'driver_first_name',
    'driver.lastName': 'driver_last_name',
    'driver.phoneNumber': 'driver_phone_number',
    'driver.title': 'driver_title',
    'driver.occupationType': 'driver_occupation_type',
    'driver.occupation': 'driver_occupation',
    'driver.isDriverOwner': 'is_driver_owner',
    'duration.endDateTime': 'duration_end',
    'duration.immediateStart': 'immediate_start',
    'duration.startDateTime': 'duration_start',
    'duration.hours': 'selected_duration_hours',
    'importantInformation.excessSelected': 'excess',
    'legal.sameNameConfirmed': 'same_name_confirmed',
    'legal.loginPoint': 'login_point',
    'legal.privacyPolicyConfirmed': 'privacy_accepted',
    'licence.country': 'licence_country',
    'licence.cassieType': 'cassie_licence_type',
    'licence.type': 'licence_type',
    'licence.licenceNumber': 'licence_manual_entry',
    'licence.fullLicenceConfirmed': 'full_provisional_confirmed',
    'licence.fullLicenceDate': 'full_provisional_date_confirmed',
    'user.status': 'user_type',
    'user.relationship': 'user_relationship',
    'user.rejectionEmail': 'rejection_email',
    'user.version': 'ab_test_version',
    'user.connection': 'user_connection',
    'user.referrer': 'referrer',
    'user.seonsession': 'seon_session',
    'user.source': 'source',
    'user.utmSource': 'utm_source',
    'user.utmMedium': 'utm_medium',
    'user.utmCampaign': 'utm_campaign',
    'user.awc': 'awin_cookie',
    'user.affiliateId': 'affiliate_id',
    'noClaimsBonus.haveNCB': 'ncb',
    'owner.email': 'owner_email',
    'owner.firstName': 'owner_first_name',
    'owner.surname': 'owner_last_name',
    'owner.phoneNumber': 'owner_phone_number',
    'owner.occupation': 'owner_occupation',
    'owner.occupationType': 'owner_occupation_type',
    'owner.save': 'owner_save',
    'owner.birthdate': 'owner_date_of_birth',
    'owner.postcode': 'owner_postcode',
    'payment.billingAddress.isManual': 'billing_address.is_manual',
    'payment.billingAddress.country': 'billing_address.country',
    'payment.billingAddress.county': 'billing_address.county',
    'payment.billingAddress.flatNumber': 'billing_address.flat_no',
    'payment.billingAddress.houseName': 'billing_address.house_name',
    'payment.billingAddress.houseNumber': 'billing_address.house_no',
    'payment.billingAddress.line1': 'billing_address.line1',
    'payment.billingAddress.line2': 'billing_address.line2',
    'payment.billingAddress.postcode': 'billing_address.postcode',
    'payment.billingAddress.road': 'billing_address.road',
    'payment.billingAddress.town': 'billing_address.town',
    'payment.billingAddress.lookupId': 'billing_address.lookup_id',
    'payment.billingAddress.udprn': 'billing_address.udprn',
    'payment.cardHolderName': 'cardholder_name',
    'payment.discountCode': 'discount_code_input',
    'payment.paperlessDelivery': 'document_delivery_preference',
    'product.isSubscription': 'is_subscription',
  },
  direct,
);
