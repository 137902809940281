import { connect } from 'react-redux';
import { Dispatch, UnknownAction } from 'redux';
import { push } from 'connected-react-router';
import { resetDuration } from '@reducer/quote/duration.actions';
import { saveAndGoTo } from '@reducer/quote/save.actions';
import { isForbiddenDuration } from '@services/date';
import { RootState } from '@redux/reducer';
import { QuoteData } from '@redux/reducer/quote/saveTypes';
import { PaymentMethod } from '@utils/types';

import Duration from './Duration';

const resetAndGoToDuration = (dispatch: Dispatch) => {
  dispatch(resetDuration());
  dispatch((push('duration-of-cover') as unknown) as UnknownAction);
};

const mapStateToProps = (state: RootState) => {
  const {
    quote: {
      duration: { endDateTime, endChoiceSelected, startDateTime },
      indicativePrice: { loading },
      product: { productType, paymentMethod },
      driver: { birthdate },
    },
  } = state;

  const disableQuickDateSelectionButton = isForbiddenDuration(startDateTime, endDateTime) ?? false;

  return {
    productType,
    paymentMethod: paymentMethod as PaymentMethod,
    loading,
    disableQuickDateSelectionButton,
    endChoiceSelectedValue: endChoiceSelected && endChoiceSelected.value,
    birthdate,
  };
};

const nextPage = (
  quote: QuoteData,
  altProduct: { passedUW: boolean; price: number | null },
  productType: string,
) => {
  if (
    altProduct &&
    altProduct.price &&
    (productType === 'newdriver' || altProduct.price <= Number(quote.price))
  ) {
    return 'product-choice';
  }

  if (!quote.underwriting_criteria || quote.price === null) {
    return 'rejection';
  }

  return 'important-information';
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetAndGoToDuration: () => resetAndGoToDuration(dispatch),
  submit: () => {
    dispatch(
      saveAndGoTo(
        (
          data: QuoteData,
          altProduct: { passedUW: boolean; price: number | null },
          productType: string,
        ) => nextPage(data, altProduct, productType),
      ),
    );
  },
});

const DurationContainer = connect(mapStateToProps, mapDispatchToProps)(Duration);

export default DurationContainer;
