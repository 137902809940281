import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';

import type { AppDispatch } from '../redux/store';
import type { RootState } from '../redux/reducer';

// App-typed useDispatch and useSelector with the types baked in
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Optimizely hooks
export { default as useOptimizelyFlag, useOptimizelyFlagVariation } from './useOptimizelyFlag';
export { default as useOptimizelyTrack } from './useOptimizelyTrack';
export { default as useComponentId, ComponentType } from './useComponentId';
export type { OptimizelyFlag } from './useOptimizelyFlag';
