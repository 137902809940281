import React, { useState } from 'react';
import { DurationOptions, Durations } from '@config/indicativeDurations';
import { Button, InformationBox } from '@rentecarlo/component-library';
import { ReactComponent as ArrowLeftIcon } from '@assets/arrow-left-icon.svg';
import { useOptimizelyTrack } from '@hooks';
import styled from 'styled-components';
import { UseUpSellComponentHook } from './UpSellComponent.hook';

const UpSellText = styled.div`
  margin-top: 8px;
`;

const ButtonContainer = styled.div`
  margin-top: 8px;
  width: 180px;
`;

interface Props {
  durations: Durations;
  updateQuote: (option: DurationOptions) => void;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const UpSellComponent = ({
  selectedTab,
  durations,
  updateQuote,
  setSelectedTab,
}: Props): JSX.Element => {
  const [hasSeenUpSell, setHasSeenUpSell] = useState(false);
  const sendEvent = useOptimizelyTrack();
  const { displayPrice, displayHours, durationToUpSell, displayUpSell } = UseUpSellComponentHook(
    durations,
    selectedTab,
  );

  if (hasSeenUpSell || selectedTab !== 'hours' || !displayUpSell || !durationToUpSell) {
    return <div />;
  }

  const handleUpSellClick = () => {
    setHasSeenUpSell(true);
    updateQuote(durationToUpSell);
    if (durationToUpSell.sub === 'DAY') setSelectedTab('days');
    sendEvent('indic_price_up_sell_click');
  };

  return (
    <InformationBox
      id='duration-component-informationBoxHoursUpSell'
      type='important'
      showLabel={false}
      subheading='DID YOU KNOW'
    >
      <UpSellText id='duration-text-upSellHoursValue'>{`\nYou can get ${displayHours} more hours for £${displayPrice} more.`}</UpSellText>
      <ButtonContainer>
        <Button
          height={42}
          id='duration-button-hoursUpSellUpdateMyQuote'
          onClick={handleUpSellClick}
        >
          <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}
            id='duration-button-updateMyQuote'
          >
            Update my quote
            <ArrowLeftIcon style={{ fill: 'white' }} />
          </div>
        </Button>
      </ButtonContainer>
    </InformationBox>
  );
};

export default UpSellComponent;
