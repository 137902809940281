import { all } from 'redux-saga/effects';

import handleError from '../utils/errorHandler';

import { runInit } from './init';
import { watchAnalytics } from './analytics';
import { watchApp } from './app';
import { watchConfig } from './config';
import { watchConnections } from './connections';
import { watchCustomer } from './customer';
import { watchChangeProduct } from './changeProduct';
import { watchIndicativePrice } from './indicativePrice';
import { watchLogout, watchLogin } from './login';
import { watchLookup } from './lookup';
import { watchPaymentRequest, watchPaymentSuccess } from './payment';
import { watchPrefill } from './prefill';
import { watchQuote } from './quote';
import { watchRegister } from './register';
import { watchResetQuote } from './resetQuote';
import { watchSave } from './save';
import { watchRouting as watchSeonRouting } from './seon';
import { watchRouting } from './routing';
import { watchBillingAddress } from './billingAddress';
import { watchLicence } from './licence';
import { watchDuration } from './duration';
import { watchAlternativeProducts } from './alternativeProducts';
import pageOrderSaga from './pageOrderGuard';

export default function* rootSaga() {
  try {
    yield all([
      runInit(),
      pageOrderSaga(),
      watchAnalytics(),
      watchApp(),
      watchBillingAddress(),
      watchConfig(),
      watchChangeProduct(),
      watchConnections(),
      watchCustomer(),
      watchDuration(),
      watchIndicativePrice(),
      watchLogin(),
      watchLogout(),
      watchLookup(),
      watchPaymentRequest(),
      watchPaymentSuccess(),
      watchPrefill(),
      watchQuote(),
      watchRegister(),
      watchResetQuote(),
      watchRouting(),
      watchSave(),
      watchSeonRouting(),
      watchLicence(),
      watchAlternativeProducts(),
    ]);
  } catch (error) {
    handleError(error);
  }
}
