import React from 'react';

import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import { useOptimizelyFlag, useOptimizelyFlagVariation, useOptimizelyTrack } from '@hooks';
import { saveAndGoTo } from '@reducer/quote/save.actions';
import { RootState } from '@redux/reducer';
import camelToSnakeCase from '@services/camelToSnakeCase';
import validation from '@services/formValidation';

import { setPaymentMethod } from '@reducer/quote/product.actions';

import QuoteStep from '@templates/QuoteStep';
import PaymentMethodSelector from '@molecules/PaymentMethodSelector';
import { PaymentMethod } from '@utils/types';

import PriceTagBox from '@atoms/blocks/PriceTagBox';
import { PriceTagContainer } from './assets/styles';

const ChooseYourCover: React.FC<InjectedFormProps> = ({ handleSubmit, valid }) => {
  const hideIndicativePrice = useOptimizelyFlag('TEMP_HIDE_INDICATIVE_SUBS').enabled;
  const learnerDiscountMessaging = useOptimizelyFlag('TEMP_LEARNER_DISCOUNT_MESSAGING').enabled;
  const indicativePriceRemoved = useOptimizelyFlagVariation('durationEnabled');
  const sendEvent = useOptimizelyTrack();

  const dispatch = useDispatch();
  const product = useSelector((state: RootState) => state.quote.product);

  return (
    <QuoteStep
      id='chooseYourCover-component-quoteStep'
      stepTitle='Choose your cover...'
      showButton
      nextDisabled={!valid}
      nextLabel='Continue'
      nextAction={handleSubmit((values: Record<string, string>) => {
        const updatedPaymentMethod = values.paymentMethod as PaymentMethod;
        if (product.paymentMethod !== updatedPaymentMethod) {
          sendEvent(`choose_your_cover_${camelToSnakeCase(values.paymentMethod)}`);
          dispatch(setPaymentMethod(updatedPaymentMethod));
        }
        // Below is for A/B test where we hide indicative price page for subs
        if (hideIndicativePrice && updatedPaymentMethod === 'subscription') {
          dispatch(saveAndGoTo('duration-of-cover'));
        } else if (indicativePriceRemoved) {
          dispatch(saveAndGoTo('car'));
        } else dispatch(saveAndGoTo('indicative-price'));
      })}
    >
      <Field
        id='chooseYourCover-field-paymentMethod'
        name='paymentMethod'
        component={PaymentMethodSelector}
        validate={[validation.isRequired]}
      />
      {learnerDiscountMessaging && product.productType === 'ldp' && (
        <PriceTagContainer>
          <PriceTagBox id='chooseYourCover-text-learnerDiscountMessaging' alignIcon='self-start'>
            When you pass and upgrade to a full licence, as a Veygo learner, you get a 10% discount
            on any policies you buy over the next year. T&Cs apply.
          </PriceTagBox>
        </PriceTagContainer>
      )}
    </QuoteStep>
  );
};

export default reduxForm({
  form: 'chooseYourCoverForm',
})(ChooseYourCover);
