/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import { Info } from '@rentecarlo/component-library';
import { useOptimizelyFlag, useOptimizelyFlagVariation } from '@hooks';

import colors from '@config/colors';
import IndicativePriceBox from '@atoms/blocks/IndicativePriceBox';
import RestrictedPeriodInfoBox from '@molecules/blocks/RestrictedPeriodInfoBox';
import DurationSummary from '@molecules/inputs/DurationSummary';
import MultipleChoice from '@molecules/inputs/MultipleChoice';
import DurationRadioButton from '@atoms/inputs/DurationRadioButton';
import { DefaultDuration } from '@services/getDefaultDuration';

import { BaseDurationConfig, DurationOptions, Durations } from '@config/indicativeDurations';
import UpSellComponent from './UpSellComponent';

interface StyledProps {
  bottomBorder: boolean;
}

const OuterContainer = styled.div`
  max-width: 400px;
`;

const OuterBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 20px;
`;

const Header = styled.div<StyledProps>`
  display: flex;
  justify-content: centre;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 0.5px solid ${colors.grey.lines.light};
  border-radius: ${({ bottomBorder }) => (bottomBorder ? '4px' : '4px 4px 0px 0px')};
  background-color: ${colors.teal};
  padding: 20px 20px ${({ bottomBorder }) => (bottomBorder ? 20 : 10)}px 20px;
`;

const Body = styled.div`
  border-radius: 0 0 4px 4px;
  width: 100%;
  background-color: ${colors.grey.background.light};
  padding: 0 10px;
`;

const Text = styled.h4`
  font-family: 'proxima-soft';
  font-weight: 300;
  text-align: center;
`;

const PricePerHour = styled(Text)`
  color: ${colors.white};
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
`;

const DurationNav = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`;

interface Props {
  openTab: string;
  getQuickSelectPrices: (subscription: boolean, defaultDuration: any) => void;
  disableQuickDateSelectionButton: boolean;
  durations: Durations;
  endChoiceSelected?: DurationOptions;
  endDateTime: Moment;
  goToDurationOfCover: () => void;
  isLoadingPrices: boolean;
  isStartDateTimeBetween22and5: boolean;
  price: string;
  selectDuration: (duration: DurationOptions | DefaultDuration) => void;
  startDateTime: Moment;
  tabOptions?: any;
  isSubscription: boolean;
  defaultDuration: DefaultDuration;
}

const IndicativePrice: React.FC<Props> = ({
  openTab,
  getQuickSelectPrices,
  durations,
  endChoiceSelected,
  endDateTime,
  goToDurationOfCover,
  isLoadingPrices,
  price = null,
  selectDuration,
  startDateTime,
  tabOptions,
  isSubscription,
  defaultDuration,
}) => {
  const [selectedTab, setSelectedTab] = useState(endChoiceSelected ? openTab : defaultDuration.tab);
  const subsFuturePurchase = useOptimizelyFlag('TEMP_SUBS_FUTURE_PURCHASE');
  const enableDurationUpSell = useOptimizelyFlagVariation('upSellEnabled');

  useEffect(() => {
    getQuickSelectPrices(isSubscription, defaultDuration);
  }, [getQuickSelectPrices, isSubscription, defaultDuration]);

  const durationOptions = () => {
    const selectedDuration = durations[selectedTab as keyof BaseDurationConfig];

    if ('options' in selectedDuration) {
      return selectedDuration.options.filter((option) => option.validate);
    }

    return [];
  };

  const filteredTabOptions = () => {
    const between20And05 =
      moment().isAfter(moment({ h: 20, m: 0, s: 0 })) ||
      moment().isBefore(moment({ h: 5, m: 0, s: 0 }));

    if (between20And05) return tabOptions.filter((elem: any) => elem.value !== 'hours');

    return tabOptions;
  };

  return (
    <OuterContainer>
      <OuterBox>
        <Header id='indicativePrice-container' bottomBorder={isSubscription}>
          <IndicativePriceBox
            price={price}
            isLoading={isLoadingPrices}
            isSubscription={isSubscription}
          />
          <DurationSummary
            isSubscription={isSubscription}
            goToDurationOfCover={goToDurationOfCover}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
            subsFuturePurchaseEnabled={subsFuturePurchase.enabled}
          />
          {!isSubscription ? (
            <PricePerHour id='indicativePrice-text-selectDuration'>
              Select a duration to get a price
            </PricePerHour>
          ) : (
            subsFuturePurchase.enabled && (
              <PricePerHour id='indicativePrice-text-tip'>
                Tap 'starting now' to change start date/time
              </PricePerHour>
            )
          )}
        </Header>
        {!isSubscription && (
          <Body>
            <DurationNav>
              <MultipleChoice
                id='indicativePrice-component-durationNav'
                selectorType={undefined}
                onSelect={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSelectedTab(e.target.value)
                }
                options={filteredTabOptions()}
                value={selectedTab}
                squashed
              />
            </DurationNav>
            <MultipleChoice
              id='indicativePrice-component-durationNav'
              selectorType={undefined}
              onSelect={(durationObject: DurationOptions) => {
                selectDuration(durationObject);
              }}
              options={durationOptions()}
              customButton={DurationRadioButton}
              value={endChoiceSelected || defaultDuration.value}
              scrollable
              squashed
            />
            {selectedTab === 'months' && (
              <Info id='indicativePrice-text-infoMonthDuration'>
                To keep things simple, each month equals 30 days of cover
              </Info>
            )}
            <RestrictedPeriodInfoBox getCurrentRestrictedPeriod={[{}]} />
          </Body>
        )}
        {enableDurationUpSell && (
          <UpSellComponent
            durations={durations}
            updateQuote={(durationObject: DurationOptions) => selectDuration(durationObject)}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        )}
      </OuterBox>
    </OuterContainer>
  );
};

export default IndicativePrice;
