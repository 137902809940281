import { createInstance } from '@optimizely/react-sdk';
import {
  getOptimizelyUserIdentity,
  optimizelyOverrideAttributes,
} from '@redux/reselectors/optimizely';
import { select } from 'redux-saga/effects';

export const optimizelyClient = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_KEY,
  logLevel: 'warn',
});

export function* trackEvent(eventName, values = {}) {
  const userId = yield select(getOptimizelyUserIdentity);
  const attributes = yield select(optimizelyOverrideAttributes);

  optimizelyClient.track(eventName, userId, attributes, values);
}
