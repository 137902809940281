import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { getYourAgreementWithAbleUrl, getTermsAndConditionsUrl } from '@reselectors/files';
import { getFaqUrl, getHelpCentreUrl } from '@reselectors/links';
import Footer from './Footer';

const mapStateToProps = (state) => ({
  faqUrl: getFaqUrl(state),
  yaweuiUrl: getYourAgreementWithAbleUrl(state),
  helpCentreUrl: getHelpCentreUrl(state),
  conditionsOfUseUrl: getTermsAndConditionsUrl(state),
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = {
  push,
};

const FooterContainer = connect(mapStateToProps, mapDispatchToProps)(Footer);

export default FooterContainer;
