/* eslint-disable @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
// Validation for page order. This makes sure
// that if the customer hasn't filled in all
// of the data for a particular page, they are
// redirected there.
// ORDER IN THIS FILE IS IMPORTANT!
import { RootState } from '@redux/reducer';

export type PageData = {
  hasRequiredData: boolean;
  url: string;
  products?: string[];
};

const isPageViewableByProductType = (productType: string) => (page: PageData) =>
  !page.products || page.products.includes(productType);

export default (indicativePriceRemoved: boolean, state: RootState) => {
  const {
    quote: {
      product: { productType },
    },
  } = state;

  const pages = [
    {
      url: '/start',
      hasRequiredData: state.quote.legal.privacyPolicyConfirmed === true,
    },
    {
      url: '/quote-start',
      hasRequiredData:
        state.quote.driver.address.postcode !== '' &&
        state.quote.driver.birthdate !== null &&
        state.quote.product.productType !== '' &&
        state.quote.save.uuid !== '',
    },
    {
      url: '/choose-your-cover',
      products: ['csi', 'ldp', 'tc'],
      hasRequiredData: true,
      // (TEMP_ENABLE_SUBS) Replace with the following once the flag has been yeeted
      // state.quote.product.paymentMethod !== null
    },
    ...(!indicativePriceRemoved
      ? [
          {
            url: '/indicative-price',
            products: ['csi', 'ldp', 'tc'],
            hasRequiredData: true,
          },
          {
            url: '/duration-of-cover',
            hasRequiredData:
              state.quote.duration.endDateTime !== null &&
              state.quote.duration.startDateTime !== null,
          },
        ]
      : []),
    {
      url: '/car',
      hasRequiredData: !!(state.pages.completed.carDetails && state.quote.car.hasInsurance),
    },
    {
      url: '/about-you',
      hasRequiredData:
        state.quote.driver.email !== '' &&
        state.quote.driver.firstName !== '' &&
        state.quote.driver.lastName !== '' &&
        state.quote.driver.phoneNumber !== '' &&
        state.quote.driver.title !== '' &&
        state.quote.driver.occupationType !== '' &&
        state.quote.driver.occupation !== '',
    },
    {
      url: '/driver-details-summary',
      hasRequiredData: true,
    },
    {
      url: '/driving-profile',
      products: ['newdriver', 'tc'],
      hasRequiredData:
        state.quote.product.productType === 'tc' ||
        (state.quote.newdriver.expectedMileage !== null &&
          state.quote.newdriver.overnightLocation !== null &&
          state.quote.newdriver.hasAlternateVehicle !== null),
    },
    {
      url: '/driving-history',
      hasRequiredData:
        state.quote.claims.hasClaims !== null &&
        state.quote.noClaimsBonus !== null &&
        state.quote.licence.licenceNumber !== '',
    },
    ...(indicativePriceRemoved
      ? [
          {
            url: '/duration',
            products: ['csi', 'ldp', 'tc'],
            hasRequiredData: true,
          },
          {
            url: '/duration-of-cover',
            hasRequiredData:
              state.quote.duration.endDateTime !== null &&
              state.quote.duration.startDateTime !== null,
          },
        ]
      : []),
    {
      url: '/product-choice',
      hasRequiredData: true,
    },
    {
      url: '/important-information',
      hasRequiredData: true,
      // TODO add policy confirmation when we start saving it to store & quote
    },
    {
      url: '/final-quote',
      hasRequiredData: true,
    },
    {
      url: '/quote-register',
      hasRequiredData: state.account.login.loggedIn,
    },
    {
      url: '/almost-there',
      hasRequiredData: state.quote.payment.termsAndConditionsAccepted === true,
    },
    {
      url: '/payment',
      hasRequiredData: state.quote.payment.paid === true,
    },
  ];

  return pages.filter(isPageViewableByProductType(productType));
};
